import { Plugin } from '@nuxt/types'
// eslint-disable-next-line import/named
import { Dictionary } from 'vue-router/types/router'
import { IDynamicObject, ListRequest } from '~/types/shared'
import { IUtil } from '~/types/util'

// eslint-disable-next-line max-lines-per-function
const utilPlugin: Plugin = ({
  route,
  i18n,
  $notification,
  $config
}, inject) => {
  const util: IUtil = {
    checkForDefaultValues (queryObj, searchParams) {
      if (queryObj.Page === 1) {
        delete queryObj.Page
      }

      if (queryObj.PageSize === 20) {
        delete queryObj.PageSize
      }

      for (const key in searchParams) {
        if (!searchParams[key]) {
          delete queryObj[key]
        }
      }

      return queryObj
    },
    getZoomForCity (cityID = 0) {
      if (cityID === 27) {
        return 3
      }

      return 1
    },
    areCoordinatesDistanceApart (
      lat1: number,
      lon1: number,
      lat2: number,
      lon2: number,
      distanceInMeters: number
    ): boolean {
      const earthRadiusKm = 6371

      const dLat = (lat2 - lat1) * (Math.PI / 180)
      const dLon = (lon2 - lon1) * (Math.PI / 180)

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const distanceKm = earthRadiusKm * c

      return Math.abs(distanceKm * 1000 - distanceInMeters) < 1
    },

    applyQueryOverwrites (params, overwrites): Dictionary<string | (string | null)[]> {
      const res = Object.assign({}, params)

      if (!overwrites) {
        return res
      }

      overwrites.forEach((overwrite: string, original: string) => {
        if (params[original] !== undefined) {
          res[overwrite] = params[original]
          delete res[original]
        }
      })

      return res
    },
    revertQueryOverwrites (params, overwrites): Dictionary<string | (string | null)[]> | IDynamicObject {
      const res = Object.assign({}, params)

      if (!overwrites) {
        return res
      }

      overwrites.forEach((overwrite: string, original: string) => {
        if (params[overwrite] !== undefined) {
          res[original] = params[overwrite]
          delete res[overwrite]
        }
      })

      return res
    },
    parseQuery (searchParams, exceptions, overwrites?) {
      const query = overwrites ? this.revertQueryOverwrites(searchParams || route.query, overwrites!) : searchParams || route.query

      let searchFilters: ListRequest = {}
      const tempData: any = Object.assign({}, query)

      const tryParse: IDynamicObject = {}

      // TODO: filtertype as InputTypeString
      Object.entries(tempData).forEach(([k, v]: any) => {
        tryParse[k] = exceptions?.includes(k) ? v : parseInt(v) || v
      })

      const sortObj = {
        [tempData.SortBy]: tempData.SortDesc ? 'asc' : 'desc'
      }

      searchFilters = {
        Page: parseInt(tempData.Page),
        PageSize: parseInt(tempData.PageSize),
        Search: tryParse,
        Sort: tempData.SortBy ? sortObj : undefined
      }

      Object.entries(tryParse).forEach(([k, v]: any) => {
        if (typeof v === 'string' && v.length < 3) {
          delete tryParse[k]
        }
      })

      delete searchFilters.Search!.Page
      delete searchFilters.Search!.PageSize
      delete searchFilters.Search!.SortBy
      delete searchFilters.Search!.SortDesc

      return searchFilters
    },
    boldLicenseNumbers (license: string, className: string = ''): string {
      const numGen = (match: string): string => {
        return `<span class="${className}">${match}</span>`
      }

      const regex = /[0-9]*/g

      return license?.replace(regex, numGen)
    },
    thousandsSeparator (x, symbol = ' ', forceZero = true) {
      if (!x || x === 0) {
        if (forceZero) {
          const number = 0

          return number.toFixed(2)
        } else {
          return 0
        }
      }

      const hasDecimals = x % 1 > 0

      if (forceZero) {
        return x.toFixed(hasDecimals ? 2 : 2).replace(/\B(?=(\d{3})+(?!\d))/g, symbol)
      } else {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, symbol)
      }
    },
    monthsMap: [
      i18n.tc('months.Jan'),
      i18n.tc('months.Feb'),
      i18n.tc('months.Mar'),
      i18n.tc('months.Apr'),
      i18n.tc('months.May'),
      i18n.tc('months.Jun'),
      i18n.tc('months.Jul'),
      i18n.tc('months.Aug'),
      i18n.tc('months.Sep'),
      i18n.tc('months.Oct'),
      i18n.tc('months.Nov'),
      i18n.tc('months.Dec')
    ],
    computeDate (input) {
      if (!input || isNaN(input)) {
        return ''
      }

      const dateFromTimestamp = new Date(input)
      const month = dateFromTimestamp.getMonth()
      const date = dateFromTimestamp.getDate()
      const year = dateFromTimestamp.getFullYear()
      const realMonth = month + 1

      return `${date < 10 ? '0' + date : date}.${realMonth < 10 ? '0' + realMonth : realMonth}.${year} ${dateFromTimestamp.toLocaleTimeString('en-GB', { hour12: false }).substring(0, 5)}`
      // return `${util.monthsMap[month]} ${date}, ${year} ${dateFromTimestamp.toLocaleTimeString(i18n.locale, { hour12: false })}`
    },
    computeTime (input) {
      if (!input || isNaN(input)) {
        return ''
      }

      const dateFromTimestamp = new Date(input)

      return `${dateFromTimestamp.toLocaleTimeString('en-GB', { hour12: false }).substring(0, 5)}`
      // return `${util.monthsMap[month]} ${date}, ${year} ${dateFromTimestamp.toLocaleTimeString(i18n.locale, { hour12: false })}`
    },
    computeDateOnly (input) {
      if (!input || isNaN(input)) {
        return ''
      }

      const dateFromTimestamp = new Date(input)
      const month = dateFromTimestamp.getMonth()
      const date = dateFromTimestamp.getDate()
      const year = dateFromTimestamp.getFullYear()
      const realMonth = month + 1

      return `${date < 10 ? '0' + date : date}.${realMonth < 10 ? '0' + realMonth : realMonth}.${year}`
      // return `${util.monthsMap[month]} ${date}, ${year} ${dateFromTimestamp.toLocaleTimeString(i18n.locale, { hour12: false })}`
    },
    errorHandler (error) {
      if (!error) {
        return
      }
      if (error.data?.GlobalError) {
        // $notification.show({
        //   type: 'error',
        //   message: `${error.data?.GlobalError}`
        // })
      } else if (error.networkError && !error.graphQLErrors) {
        $notification.show({
          type: 'error',
          message: 'Нещо се обърка. Опитваме се да го разрешим възможно най-скоро.'
        })
      } else if (!$config.isDevEnv) {
        // $sentry.captureException(error)
      }
    },
    translateSelect (items) {
      const result = items.map((item: any) => {
        Object.entries(item).forEach(([k, v]) => {
          if (k === 'Label' && i18n.te(`select.${v}`)) {
            item[k] = i18n.t(`select.${v}`)
          }
        })

        return item
      })

      return result
    },
    convertToTimestamp (date, isEndOfDay = false) {
      if (!date) {
        return
      }
      // this creates date object in local timezone
      const dateObj = new Date(date)
      // get timestamp
      const timestamp = dateObj.valueOf()
      // get timezone difference in minutes
      const timezoneOffset = dateObj.getTimezoneOffset()
      // // calculate timezone difference to +0
      // const localTimestamp = timestamp + (timezoneOffset * 60 * 1000)
      const localTimestamp = timestamp + (timezoneOffset * 60 * 1000)
      const endOfDay = ((24 * 60 * 60 * 1000) - 1)

      return isEndOfDay ? localTimestamp + endOfDay : localTimestamp
    },
    convertToDate (val, mandatory = true) {
      if (typeof val === 'undefined' && mandatory) {
        return
      }
      // this creates date object in local timezone
      let dateObj
      if (val) {
        dateObj = new Date(val)
      } else {
        dateObj = new Date()
      }
      // get timestamp
      const timestamp = dateObj.valueOf()
      // get timezone difference in minutes
      const timezoneOffset = dateObj.getTimezoneOffset()
      // calculate timezone difference to local timezone
      const localTimestamp = timestamp - (timezoneOffset * 60 * 1000)
      // create new date object in +0 timezone
      const result = new Date(localTimestamp)

      return result
    },
    getFirstDatOfMonth () {
      const date = new Date()
      const firstDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        2
      )

      return firstDay.toISOString().substring(0, 10)
    },
    isTablet () {
      const userAgent = navigator.userAgent.toLowerCase()

      return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent) || /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(userAgent)
    }
  }
  inject('util', util)
}

declare module 'vue/types/vue' {
  // this.$util inside Vue components
  interface Vue {
    $util: IUtil
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$util inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $util: IUtil
  }

  // nuxtContext.$util
  interface Context {
    $util: IUtil
  }
}

export default utilPlugin
