export default {
  validation: {
    alreadyExists: '{0} {1} Вече съществува', // example with placeholders. Don't remove yet
    signup_failed: 'Влизането се провали',
    required: 'Това поле е задължително',
    email: 'Моля въведете правилен имейл формат',
    numeric: 'Трябва да бъде цифрено число',
    minLengthOf: 'Минималната дължина е {0}',
    maxLengthOf: 'Максималната дължина е {0}',
    minValue: 'Минималната стойност е ',
    maxValue: 'Максималната стойност е ',
    repeatPassword: 'Паролата не отговаря',
    notValidRange: 'Стойността е извън диапазона',
    alpha: 'Трябва да бъде буквена стойност',
    invalid_credentials: 'Невалидни идентификационни данни',
    strongPassword: 'Трябва да съдържа поне 8 знака с поне едно число и една главна буква',
    not_properly_formatted: 'Полето не е форматирано правилно',
    invalid_phone_number: 'Невалидент телефонен номер',
    password_is_weak: 'Трябва да съдържа поне 8 знака с поне едно число и еднин специален знак',
    password_hint: 'Трябва да съдържа поне 8 знака с поне едно число и една главна буква',
    namesMinLength: 'Името трябва да съдържа повече от една буква',
    required_field: 'Това поле е задължително',
    invalid_country_code: 'Невалиден пощенски код',
    internal_server_error: 'Нещо се обърка. Опитваме се да го разрешим възможно най-скоро.',
    valid_phone_number: 'Трябва да бъде валиден телефонен номер',
    Prices: 'Поне една цена е задължителна',
    CarSlots: 'Поне едно поле за кола е задължително',
    Borders: 'Моля начертайте граници',
    no_location: 'Моля селектирайте адрес от списъка с предложения',
    no_result: 'Няма намерени резултати',
    cars_cannot_be_more_than: 'Колите не могат да бъдат повече от ',
    active_cars_cannot_be_more_than: 'Активните автомобили не могат да бъдат повече от '
  },
  menu: {
    traffic_monitoring: 'Трафик Мониторинг',
    mobile_camera_tracker: 'Мобилна камера',
    comments: 'Коментари',
    tickets_and_cards: 'Карти и билети',
    users: 'Потребители',
    employees: 'Служители',
    cameras: 'Камери',
    trade_network: 'Търговска мрежа',
    statistics: 'Статистика',
    settings: 'Настройки',
    general_settings: 'Главни настройки',
    cities: 'Градове',
    company: 'Компании',
    store: 'Търговски Обекти',
    fine_type: 'Видове глоби',
    discount: 'Отстъпки за абонаменти',
    whitelist_car: 'МПС освободени от плащане',
    subscription: 'Абонамент',
    subscriptions: 'Абонаменти',
    subscription_plan: 'Абонаментен план',
    roles_and_permissions: 'Роли и разрешения',
    user_management: 'Потребители',
    park_zone: 'Зони за паркиране',
    park_area: 'Райони за паркиране',
    mc_park_area: 'Райони за мобилни камери',
    payment_statistics: 'Плащания',
    translations: 'Управление текстове',
    translations_city: 'За градове',
    translations_common: 'Общи',
    daily_card: 'Дневни карти',
    tickets: 'Билети',
    sms: 'СМС',
    non_working_days: 'Неработни дни',
    incidents: 'Инциденти',
    active_incidents: 'Активни инциденти',
    past_incidents: 'Минали инциденти',
    finished_incidents: 'Приключени инциденти',
    fine: 'Глоби',
    fines: 'Глоби',
    payments: 'Плащания',
    reports: 'Статистика - служители',
    repatriator_activity: 'Активност Репатриращи',
    inspector_activity: 'Активност Инспектори',
    tyreblocker_activity: 'Активност Заскобяващи',
    camera_activity: 'Активност Камери',
    parking: 'Паркиране',
    repatriation_incidents: 'Репатрирани',
    camera_inspections: 'Инспекции от камери',
    employees_report: 'Отчети',
    monthly_statistics: 'Месечен (обобщен)',
    monthly_employees_statistics: 'Месечен (служители)',
    inspection_reviews: 'Списък с проверки',
    inspections: 'Проверки',
    mobile_camera: 'Мобилни Камери',
    mobile_camera_activity: 'Мобилни Камери FPS'
  },
  btn: {
    mark_as_trusted: 'отбележи като доверен',
    mark_as_not_trusted: 'отбележи като недоверен',
    cameras: 'Камери',
    detections: 'Заснемания',
    statistics: 'Статистика',
    mobile_camera_logs: 'Мобилна камера - Регистър',
    yes: 'Да',
    no: 'Не',
    release_car: 'Освободи кола',
    print: 'Принтирай',
    print_table: 'Принтирай таблица',
    print_images: 'Принтирай снимки',
    print_combined: 'Принтирай всичко',
    cancel_fine: 'Анулирай',
    preview_incident: 'Провери инцидент',
    export: 'Експорт',
    profile: 'Профил',
    change_password: 'Промени парола',
    logout: 'Изход',
    clear_all_cache: 'Изчистване на кеш',
    re_init_rbac: 'Re-init RBAC',
    login: 'Вход',
    forgotten_password: 'Забравена парола',
    submit: 'Запази',
    create: 'Създай',
    cancel: 'Затвори',
    decline_request: 'Откажи заявка',
    close: 'Затвори',
    update: 'Редакция',
    preview: 'Преглед',
    update_license: 'Обнови рег. номер',
    remove: 'Премахни',
    clear: 'Изчисти',
    actions: 'Действия',
    copy: 'Копиране',
    approve: 'Одобри',
    pay: 'Плати',
    reverse_pay: 'Отмени плащането',
    pay_with_cash: 'Плащане в брой',
    pay_with_wire: 'Плащане с карта',
    cancel_payment: 'Отмени плащането',
    payments: 'Плащания',
    cancelSubscription: 'Анулурай',
    details: 'Детайли',
    logs: 'Влизания',
    assign_hourly_prices: 'Назначи часови плащания',
    assign_daily_prices: 'Назначи дневни плащания',
    add_new_price: 'Добави нова цена',
    add_new_duration: 'Добави продължителност',
    reverse_cancel: 'Отмени ......',
    release_request: 'Искане за освобождаване',
    no_available_actions: 'Няма налични действия',
    view_subscription: 'Виж детайли',
    add_another: 'Добави',
    add_car: 'Добави ППС',
    add_price: 'Добави цена',
    confirm: 'Потвърди',
    reject: 'Откажи',
    check: 'Провери',
    select: 'Избери',
    add_missing_car: 'Добавете липсваща или неоткрита кола',
    images: 'Изображения',
    load: 'Зареди',
    accessible_parking_slot: 'Инвалидно паркомясто',
    paid_parking_space: 'Платено паркомясто',
    outside_zone: 'Извън зона',
    rejected: 'Откажи'
  },
  form: {
    camera_actions_hint: 'Изберете камери, за да извършите действия',
    user_icon: 'Проверка',
    cancelled_icon: 'Отказано заскобяване',
    AccessibleParkingSpots: 'Инвалидни паркоместа',
    PaidParkingSpots: 'Платени паркоместа',
    DriverFirstName: 'Име на шофьора',
    DriverLastName: 'Фамилия на шофьора',
    TrustedTyreBlocker: 'Доверен заскобяващ',
    CreateFinePrinterTextID: 'Текст при глоба',
    ReleaseCarPrinterTextID: 'Текст при освобождаване на автомобил',
    Forever: 'Завинаги',
    Converted: 'Превърната глоба',
    FullName: 'Име',
    Variables: 'Променливи',
    Text: 'Текст',
    Lang: 'Език',
    Active: 'Активен',
    Inactive: 'Неактивен',
    fine: 'Глоба',
    Source: 'Източник',
    ID: 'ID',
    tyreblocker_activity: 'Активност заскобяващи',
    payment_fine: 'Плащания - глоби',
    payment_parking: 'Плащания - паркинг',
    employee_app_inspection: 'Приложение за служители - инспекции',
    employee_app_fine: 'Приложение за служители - глоби',
    sms: 'СМС',
    incident: 'Инциденти',
    payment_subscription: 'Плащания - абонаменти',
    employee_app_tyre_block: 'Приложение за служители - заскобяване',
    inspector_activity: 'Активност инспектори',
    FromPhone: 'От телефон',
    ToPhone: 'Към телефон',
    preview: 'Преглед',
    city: 'Град',
    translation: 'Управление текстове',
    company: 'Компании',
    store: 'Търговски обекти',
    fine_type: 'Видове глоби',
    discount: 'Отстъпки',
    whitelist_car: 'МПС освободени от плащане',
    subscription_plan: 'Абонаментен план',
    subscription: 'Абонамент',
    role: 'Роля',
    admin_user: 'Админ потребител',
    park_zone: 'Зони за паркиране',
    park_area: 'Райони за паркиране',
    payment_statistic: 'Статистика Плащания',
    tickets: 'Билети',
    daily_card: 'Дневни карти',
    non_working_days: 'Неработни дни',
    SubscriptionDocuments: 'Документи за абонамент',
    SubscriptionPayments: 'Плащания на абонамент',
    create: 'Създаване',
    edit: 'Редактиране',
    Email: 'Имейл',
    Password: 'Парола',
    Name: 'Име',
    Tolerance: 'Толеранс',
    RepatriationAfterTyreBlockInMinutes: 'Репартиране след (min)',
    ExpireParkingReminderAfterInMinutes: 'Напомняне за изтичане след (min)',
    Status: 'Статус',
    SMSProviderMobicaSettings: {
      Username: 'Мобика потребителско име',
      Password: 'Мобика парола'
    },
    TimeZoneID: 'Времева зона',
    DefaultLanguage: 'Език по подразбиране',
    CountryID: 'Страна',
    HasSubscriptions: 'Абонаменти',
    HasDailyCards: 'Дневни карти',
    PaymentProviderIDs: 'Доставчици на плащания',
    SMSProviderID: 'СМС Доставчици',
    SMSProviderLinkMobilitySettings: {
      Shortcode: 'Кратък номер',
      Service: 'Услуга',
      Secret: 'Secret',
      Key: 'Key'
    },
    CityID: 'Град',
    Address: 'Адрес',
    OwnerName: 'Име на собственик',
    MOL: 'МОЛ',
    Phone: 'Телефон',
    VAT: 'ЕИК',
    SellerCompanyID: 'Име на обект',
    PostalCode: 'Пощенски код',
    ContactPerson: 'Лице за контакт',
    Manager: 'Мениджър',
    FineType: 'Видове глоби',
    GratisHours: 'Гратис часове',
    Price: 'Цена',
    PriceDiscount: 'Отстъпка',
    PriceForTyreBlock: 'Цена за скоба',
    PriceStayingAtParking: 'Цена на наказателен паркинг',
    PriceStayingAtParkingType: 'Вид на таксуване за наказателен паркинг',
    RequiresPhotos: 'Изискване на снимки',
    RequiresComment: 'Изискване на коментари',
    NumberOfPayments: 'Брой плащания',
    Type: 'Вид',
    DiscountPrice: 'Цена на отстъпка',
    DiscountPercentage: 'Отстъпка процент',
    License: 'Рег. номер',
    ExpireAt: 'Изтича на',
    Reason: 'Вид',
    Note: 'Бележка',
    SubscriptionPlanID: 'Абонаментен план',
    FirstName: 'Име',
    MiddleName: 'Презиме',
    LastName: 'Фамилия',
    PersonalID: 'ЕГН',
    UserAddress: 'Адрес',
    ParkAreaIDs: 'Район паркинг',
    ParkZoneIDs: 'Зона паркинг',
    ParkZones: 'Паркинг зони',
    SelectAll: 'Избери всичко',
    CompanyAddress: 'Адрес на компанията',
    StartDate: 'Започва на',
    EndDate: 'Приключва на',
    SubscriptionPlanPriceID: 'Цена на абонаментен план',
    IsActive: 'Активно',
    SubmissionNumber: 'Номер на подаването',
    ThirdPartyID: 'Трето лице',
    Duration: 'Продължителност (months)',
    DurationMinutes: 'Продължителност (minutes)',
    DurationDays: 'Продължителност (days)',
    CarSlotType: 'Тип слот за кола',
    MaxAllowedCarSlots: 'Максимално позволени коли',
    MaxAllowedActiveCarSlots: 'Максимално позволени активни коли',
    PriceName: 'Име на цена',
    ExpireSubscriptionReminderInMinutes: 'Напомняне на изтичане на абонамент (минути)',
    ExpireSubscriptionReminderInDays: 'Напомняне на изтичане на абонамент (дни)',
    PaymentSubscriptionReminderInDays: 'Напомняне на предстоящо плащане (дни)',
    DiscountIDs: 'Отстъпки',
    ShouldBeApproved: 'Трябва да бъде одобрено',
    IsFixedDuration: 'Редактирай продължителност',
    UserFullName: 'Име на потребителя',
    Prices: 'Цени',
    parking_lot: 'Паркинг',
    car_number: 'Рег. номер',
    up_to: 'До',
    infinite: 'Безкрай',
    CarSlots: 'Коли',
    SubscriptionID: 'Абонамент ID',
    Amount: 'Сума',
    Discount: 'Отстъпка',
    Total: 'Всичко',
    hasUserCompany: 'Юридическо лице',
    Permissions: 'Разрешения',
    RoleID: 'Роля',
    FlowbirdID: 'Flowbird ID',
    MaxParkingHours: 'Максимални часове за паркиране',
    ParkZoneSchedule: 'График на паркова зона',
    AllowFullDayPark: 'Позволи паркиране за цял ден',
    SMSCode: 'SMS код',
    SMSNumber: 'SMS номер',
    Capacity: 'Брой парко места',
    ParkZoneID: 'Зона за паркиране',
    OldPassword: 'Стара парола',
    NewPassword: 'Нова парола',
    NewPasswordConfirmation: 'Повтори нова парола',
    PasswordConfirmation: 'Повтори парола',
    outgoing_sms_provider: 'Външен СМС доставчик',
    general: 'Главни',
    parking_settings: 'Настройки паркиране',
    Day: 'Ден',
    From: 'От',
    To: 'До',
    Category: 'Категория',
    EditorType: 'Вид редактиране',
    Translations: 'Управление текстове',
    Number: 'Номер',
    TicketNumber: 'Номер на билет',
    StoreID: 'Търговски обекти',
    end_of_day: 'Края на деня',
    same_time: 'Старт време',
    FromDate: 'От дата',
    ToDate: 'До дата',
    Key: 'Ключ',
    AdminUserID: 'Име на инспектор',
    CanceledAdminUserID: 'Затворил инспектор',
    PaymentProviderMethod: 'Метод на плащане',
    FineTypeID: 'Вид глоба',
    CheckMethod: 'Метод на проверка',
    PaymentProviderCode: 'Начин на  плащане',
    InspectionCreatedAt: 'Време на проверката',
    Accuracy: 'GPS Точност',
    ParkAreaName: 'Име на район',
    StoreTicketsAndDailyCards: 'Билети и дневни карти',
    Payments: 'Плащания',
    TodayPayments: 'Днешни плащания',
    NextDayPayments: 'Утрешни плащания',
    WhiteLists: 'МПС Освободени от плащания',
    ActiveSubscriptions: 'Абонаменти',
    InactiveSubscriptions: 'Неактивни абонаменти',
    employee_app_cash: 'В брой',
    employee_app_wire: 'С карта',
    Comment: 'Коментар',
    PaymentCode: 'Платено с',
    PriceOnField: 'Цена на терен',
    PriceOnFieldCheckbox: 'Плащане на терен',
    PaidOnFieldPrinterTextID: 'Текст при плащане на терен',
    PaidOnFieldPrinterTextIDCheckbox: 'Бележка при плащане на терен',
    MaxPricePerDay: 'Максимална цена за ден',
    FineTypeIDs: 'Списък с глоби',
    TotalDays: 'Дни',
    TotalHours: 'Часове',
    TotalPrice: 'Сума за плащане',
    CameraID: 'Камера ID',
    ParkingLotID: 'Паркинги',
    Trusted: 'Доверени',
    NotTrusted: 'Недоверени',
    Inspections: 'Проверки',
    Details: 'Детайли',
    OriginalLicense: 'Оригинален ре. номер',
    Photo: 'Снимка',
    ParkZoneName: 'Зона за паркиране',
    ParkingLotName: 'Паркинг',
    ParkingSpaceName: 'Парко място',
    Location: 'Адрес',
    OriginalAddress: 'Оригинален адрес',
    City: 'Град',
    cant_change_moderation_status: 'Не може да се промени състоянието на модериране на тази проверка',
    inspection_image: 'Изабражение от инспекция',
    live_camera_image: 'Изображение от камера на живо',
    tyreblock_images: 'Изображения от поставяне на скоба',
    image: 'Изображение',
    unblocked: 'Освобождаване',
    release_request_created: 'Създадена заявка за освобождаване',
    calculate_fine_from: 'Изчисляване на цена от',
    default: 'По подразбиране',
    enabled: 'Включено',
    disabled: 'Изключено',
    printing: 'Принтиране',
    disableSubscriptionEmail: 'Изключване на Имейл за Абонамент',
    disableSubscriptionSMS: 'Изключи абонаментните SMS',
    paymentAfterExpiredTolerance: 'Позволи плащане след изтекъл толеранс.',
    ParentIDs: 'Основни райони',
    inspection: 'Проверка',
    camera: 'Камера'
  },
  table: {
    trusted: 'доверен',
    not_trusted: 'недоверен',
    accessible_parking_slot: 'Инвалидно паркомясто',
    paid_parking_space: 'Платено паркомясто',
    outside_zone: 'Извън зона',
    rejected: 'Откажи',
    invisible: 'Непълен/Невидим номер',
    LastUpdate: 'Последна локация',
    accepted_requests: 'Приети заявки',
    paid_on_field: 'Платени на терен',
    repatriated: 'Репатрирани',
    Environment: 'Среда',
    Version: 'Версия',
    Link: 'Линк',
    Download: 'Свали',
    Changes: 'Промени',
    Lang: 'Език',
    Key: 'Ключ',
    Text: 'Текст',
    Comment: 'Коментар',
    PersonalID: 'ЕГН',
    CarSlots: 'Коли',
    ParkAreas: 'Райони за паркиране',
    ticket_machine: 'Машина за билети',
    Source: 'Източник',
    TypeName: 'Име на план',
    PlanName: 'План',
    Active: 'Статус на кола?',
    PaidTo: 'Платено до',
    success: 'Валиден',
    failed: 'Невалиден',
    RawLicense: 'Непроменен рег. номер',
    cash: 'Кеш',
    SMSProvider: 'SMS Доставчик',
    FromPhone: 'От телефон',
    ToPhone: 'Към телефон',
    ID: 'ID',
    Team: 'Екип',
    FineTypeName: 'Вид глоба',
    UserName: 'Потребителско име',
    Status: 'Статус',
    Name: 'Име',
    Company: 'Компания',
    Type: 'Вид',
    Price: 'Цена',
    Percentage: 'Процент',
    License: 'Рег. номер',
    City: 'Град',
    Reason: 'Вид',
    ExpireAt: 'Изтича на',
    UpdatedAt: 'Обновено на',
    CreatedAt: 'Съзнадено на',
    Actions: 'Действия',
    Amount: 'Сума',
    pending_review: 'Чака преглед',
    pending_payment: 'Чака плащане',
    declined: 'Отказано',
    expired: 'Изтекло',
    canceled: 'Анулирано',
    not_paid: 'Няма плащане',
    Role: 'Роля',
    Email: 'Имейл',
    payment_provider: 'Доставчик на плащане',
    payment_count: 'Номер на плащания',
    payment_amount: 'Сума на плащания',
    parking_daily: 'Дневно паркиране',
    parking_hourly: 'Часово паркиране',
    CityName: 'Име на Град',
    FromDate: 'От дата',
    ToDate: 'До дата',
    IssuedDate: 'Дата на издаване',
    TicketCount: 'Номер на билети',
    TicketAmount: 'Общо сума билети',
    DailyCardsCount: 'Номер на дневни кати',
    DailyCardsAmount: 'Общо сума дневни карти',
    DailyCardsTicketsTotalAmount: 'Общо сума на дневни карти и билети',
    CanceledTicketCount: 'Общо отказани билети',
    CanceledTicketAmount: 'Сума общо отказани билети',
    CanceledDailyCardsCount: 'Общо отказани дневни карти',
    CanceledDailyCardsAmount: 'Сума общо отказани дневни карти',
    CanceledDailyCardsTicketsTotalAmount: 'Сума общо отказани дневни карти и билети',
    EditorType: 'Вид редактиране',
    TicketNumber: 'Номер на билет',
    Store: 'Търговски обекти',
    Duration: 'Продължителност',
    StartDate: 'Започва на',
    EndDate: 'Приключва на',
    Category: 'Категория',
    TotalAmount: 'Общо сума',
    dailyPriceDuration: 'Продължителнонст: {0} дни, Вид: {1}',
    Date: 'Дата',
    Phone: 'Телефон',
    ShortNumber: 'Кратък номер',
    ParkZoneID: 'Зона за паркиране ID',
    ParkZoneName: 'Зона за паркиране',
    ParentIncident: 'Първоначален инцидент',
    InspectorName: 'Име на инспектор',
    Location: 'Локация',
    Address: 'Адрес',
    OriginalAddress: 'Адрес при заскобяване',
    TyreBlockName: 'Име на заскобяващ',
    DueDate: 'Дата на изтичане',
    CanceledAt: 'Отказано на',
    CanceledInspectorName: 'Отказано от инспектор',
    Note: 'Коментар',
    Event: 'Събитие',
    Images: 'Снимки',
    FineType: 'Вид глоба',
    PaymentProviderMethod: 'Начин на плащане',
    AdminUserEmail: 'Имейл на админ потребител',
    SubscriptionPlanName: 'Абонаментен план',
    SubscriptionOwnerName: 'Собственик на абонамента',
    PaymentMethod: 'Начин на плащане',
    PaymentDue: 'Плащането изтича',
    CheckMethod: 'Начин на проверка',
    Result: 'Резултат',
    FineStatus: 'Статус на глобата',
    Checks: 'Проверки',
    BlockRequest: 'Заявка за скоба',
    Blocked: 'Блокирани',
    NoAction: 'Без действие',
    Repatration: 'Репатриране',
    Repatriation: 'Репатриране',
    Fine: 'Глоба',
    FineDiscount: 'Отстъпка на глобата',
    Canceled: 'Отменено',
    MovedToRepatration: 'Подай за репатриране',
    MovedToRepatriation: 'Подай за репатриране',
    ReleaseRequest: 'Искане на освобождаване',
    BlockedTime: 'Блокирана на',
    ReleasedTime: 'Освободена на',
    sms: 'СМС',
    checked_car: 'Проверени коли',
    block_request: 'Заявка за скоба',
    blocked: 'Блокирана',
    no_action: 'Без действие',
    repatriation_requests: 'Заявки за репатриране',
    custom_fine: 'Административна глоба',
    fine_discount: 'Отстъпка глоба',
    release_request: 'Заявка за освобождаване',
    PaymentProviderCode: 'Начин на  плащане',
    SMSPhone: 'SMS телефон',
    ValidUntil: 'Валидно до',
    CarID: 'Кола ID',
    Method: 'Метод',
    Code: 'Код',
    Message: 'Съобщение',
    FirstName: 'Име',
    MiddleName: 'Презиме',
    LastName: 'Фамилия',
    SubscriptionID: 'ID на абонамент',
    fine: 'Глоба',
    parking: 'Паркинг',
    provider: 'Доставчик',
    total: 'Общо',
    Subscription: 'Абонамент',
    subscription: 'Абонамент',
    ParkingLot: 'Паркинг',
    CameraID: 'Камера ID',
    LastInspection: 'Последна инспекция',
    LastInspectionImage: 'Изображение от последна инспекция',
    valid_checks: 'Валидни проверки',
    confirm_edited: 'Потвърдено с редакция',
    tyre_block_requests: 'Заявки за скоба',
    camera_actions: 'Действия на камера',
    camera_actions_total: 'Общо',
    camera_actions_corrected: 'Редактирани',
    camera_actions_confirmed: 'Потвърдени',
    camera_actions_denied: 'Отказани',
    camera_actions_expired: 'Изтекли',
    camera_actions_auto_confirmed: 'Потвърдени автоматично',
    camera_actions_auto_rejected: 'Отказани автоматично',
    camera_actions_pending: 'Изчакващи',
    employee_actions: 'Действия на служители',
    employee_actions_total: 'Общо',
    employee_actions_confirmed: 'Потвърдени',
    employee_actions_corrected: 'Редактирани',
    employee_actions_denied: 'Отказани',
    parking_lots: 'Паркинги',
    active_incidents: 'Активни инциденти',
    trusted_camera_inspections: 'Проверки от доверени камери',
    not_trusted_camera_inspections: 'Проверки от недоверени камерии',
    trusted_cameras: 'Доверени камери',
    not_trusted_cameras: 'Недоверени камери',
    last: 'последни',
    image_loading_smartalot: 'Извличане на изображения от SmartALot',
    wire: 'С карта',
    integration: 'Интеграция',
    IsConverted: 'Конвертирано',
    ConvertedAt: 'Конвертирано в',
    mobile_camera: 'Мобилни Камери',
    trusted_mobile_camera_inspections: 'Проверки от доверени мобилни камери',
    not_trusted_mobile_camera_inspections: 'Проверки от недоверени мобилни камери',
    not_trusted_mobile_camera: 'Недоверени мобилни камери',
    trusted_mobile_camera: 'Доверени мобилни камери'
  },
  page: {
    apps: 'Мобилни приложения',
    comments: 'Коментари',
    history: 'История',
    'camera-management': 'Камери',
    login: 'Вход',
    forgotten_password: 'Забравена парола',
    cities: 'Градове',
    city: 'Град',
    store: 'Обекти',
    'fine-type': 'Видове глоби',
    fine_type: 'Видове глоби',
    discount: 'Отстъпки за абонаменти',
    whitelist_car: 'МПС освободени от плащане',
    subscription_plan: 'Абонаментен план',
    'subscription-plan': 'Абонаментен план',
    subscription: 'Абонаменти',
    company: 'Компании',
    'subscription-payments': 'Плащания Абонаменти',
    before: 'Преди',
    changes: 'Промени',
    role: 'Роли и разрешения',
    'user-management': 'Потребители',
    'park-zone': 'Зона за паркиране',
    'park-area': 'Район за паркиране',
    'change-password': 'Промени парола',
    reset_password: 'Възобнови парола',
    payment_statistics: 'Статистика плащания',
    payment_details: 'Плащания детайли',
    daily_card: 'Дневни карти',
    translations: 'Управление текстове',
    translations_city: 'Управление текстове - градове',
    translations_common: 'Управление текстове - общи',
    tickets: 'Билети',
    sms: 'СМС',
    non_working_days: 'Неработни дни',
    incidents_finished: 'Завършени инциденти',
    incidents_active: 'Текущи инциденти',
    incidents_past: 'Инциденти - предишен ден',
    incidents_repatriation: 'Инциденти - Репатрирани',
    fine: 'Глоби',
    payments_fines: 'Плащания - Глоби',
    payments_subscriptions: 'Плащания - Абонаменти',
    payments_parking: 'Плащания - Паркинг',
    inspector_activity: 'Активност инспектори',
    tyreblocker_activity: 'Активност Заскобяващи',
    inspector_activity_details: 'Активност инспектори - Детайли',
    tyreblocker_activity_details: 'Активност заскобяващи - Детайли',
    repatriator_activity: 'Активност репатриращи',
    repatriator_activity_details: 'Активност репатриращи - Детайли',
    camera_activity: 'Camera Activity',
    camera_activity_details: 'Camera Activity - details',
    camera_incidents_active: 'Camera Active Incidents',
    parking_lot: 'Parking Lot',
    select_image: 'Please select the image',
    no_checks_for_car: 'No checks for this car found for today',
    monthly_statistics: 'Месечен отчет - обобщен',
    monthly_employees_statistics: 'Месечен отчет - служители',
    inspection_reviews: 'Списък с проверки',
    camera_activity_actions: 'Действия по камери'
  },
  router: {
    '/': 'Начало',
    '/apps': 'Мобилни приложения',
    '/city': 'Град',
    '/company': 'Компании',
    '/store': 'Търговски обекти',
    '/fine-type': 'Видове глоби',
    '/camera-management': 'Управление на камера',
    '/discount': 'Отстъпки за абонаменти',
    '/whitelist-car': 'МПС освободени от плащане',
    '/subscription': 'Абонаменти',
    '/subscription-plan': 'Видове абонаменти',
    '/subscription/payments': 'Абонаменти - плащания',
    '/role': 'Роли и права',
    '/user-management': 'Потребители',
    '/park-zone': 'Зона за паркиране',
    '/park-area': 'Район за паркиране',
    '/mc-park-area': 'Райони за мобилни камери',
    '/change-password': 'Промени парола',
    '/statistics/payments': 'Статистика плащания',
    '/statistics/payments/details': 'Детайли',
    '/store-statistics': 'Статистика - Търговки обекти',
    '/translations-city': 'Управление текстове - градове',
    '/translations-common': 'Управление текстове - общи',
    '/daily-card': 'Дневни карти',
    '/tickets': 'Билети',
    '/sms': 'СМС',
    '/non-working-days': 'Неработни дни',
    '/incidents/active': 'Текущи инциденти',
    '/incidents/finished': 'Прикючени инциденти',
    '/incidents/past': 'Инциденти - предишен ден',
    '/incidents/repatriation': 'Инциденти - репатрирани',
    '/fines': 'Глоби',
    '/payments/fines': 'Плащания - Глоби',
    '/payments/parking': 'payments - Паркинг',
    '/payments/subscriptions': 'payments - Абонаменти',
    '/reports/inspector-activity': 'Активност Инспектори',
    '/reports/tyreblocker-activity': 'Активност Заскобяващи',
    '/reports/camera-activity-actions': 'Действия по камери',
    '/comments': 'Коментари',
    '/repatriation-incidents/active': 'Репатрирани - активни',
    '/repatriation-incidents/finished': 'Репатрирани - приключени',
    '/repatriation-incidents/past': 'Репатрирани - предишен ден',
    '/reports/repatriator-activity': 'Активност Репатриращи',
    '/incidents/cameras': 'Инциденти от камери',
    '/reports/camera-activity': 'Активност камери',
    '/employee-reports/monthly': 'Месечен отчет (обобщен)',
    '/employee-reports/monthly-employees': 'Месечен отчет (служлители)',
    '/inspections/reviews': 'Списък с проверки'
  },
  months: {
    Jan: 'Яну',
    Feb: 'Фев',
    Mar: 'Мар',
    Apr: 'Апр',
    May: 'Май',
    Jun: 'Юни',
    Jul: 'Юли',
    Aug: 'Авг',
    Sep: 'Сеп',
    Oct: 'Окт',
    Nov: 'Ное',
    Dec: 'Дек'
  },
  day: {
    Monday: 'Понеделник',
    Tuesday: 'Вторник',
    Wednesday: 'Сряда',
    Thursday: 'Четвъртък',
    Friday: 'Петък',
    Saturday: 'Събота',
    Sunday: 'Неделя'
  },
  warning: {
    cancelLocationNear: 'Местоположението на заявката и отказа съвпадат',
    approveSubscriptionTitle: 'Изчакайте! Сигурни ли сте, че искате да продължите?',
    cancelSubscriptionTitle: 'Изчакайте! Сигурни ли сте, че искате да продължите?',
    reverseCancelSubscriptionTitle: 'Изчакайте! Сигурни ли сте, че искате да продължите?',
    confirmDeleteTitle: 'Изчакайте! Сигурни ли сте, че искате да продължите?',
    cancelPaymentTitle: 'Изчакайте! Сигурни ли сте, че искате да продължите?',
    approveSubscriptionDescription: 'Моля, потвърдете, за да продължите.',
    cancelPaymentDescription: 'Моля, потвърдете, за да продължите.',
    cancelSubscriptionDescription: 'Моля, потвърдете, за да продължите.',
    cancelSubscriptionMessage: 'Моля, потвърдете, за да продължите.',
    reverseCancelSubscriptionDescription: 'По този начин вие също ще отмените отмененото плащане за {0}.',
    reverseCancelSubscriptionMessage: 'Моля, потвърдете, за да продължите',
    confirmDeleteDescription: 'Няма начин да върнете това действие, след като приключи.',
    paymentSummaryTitle: 'Резюме на подробностите:',
    401: 'Сесията е изтекла. Моля, влезте отново',
    no_data: 'Няма налични данни',
    no_data_for_period: 'Няма налични данни за избрания период',
    payFineTitle: 'Плати Глоба',
    payFineDescription: 'Моля, потвърдете, да за продължите ',
    reversePayFineTitle: 'Обратно плащане?',
    reversePayFineDescription: 'Моля, потвърдете, за да продължите',
    incidentConfirmReleaseTitle: 'Създай искане за освобождаване',
    incidentConfirmReleaseDescription: 'Моля, потвърдете, за да продължите',
    repatriationConfirmCancelTitle: 'Премахнете заявката за репатриране',
    repatriationConfirmCancelDescription: 'Моля, потвърдете за да продължите',
    incidentCancelTitle: 'Откажи заявка за инцидент -',
    incidentCancelDescription: 'Моля, потвърдете за да продължите',
    duration_already_exists: 'Дневна карта с тези параметри вече съществува. Моля изтрийте първо съществуващата преди да добавите нова карта.',
    confirmInspectionTitle: 'Сигурни ли сте, че искате да продължите?',
    confirmInspectionDescription: 'Моля, потвърдете, за да продължите',
    rejectInspectionTitle: 'Сигурни ли сте, че искате да продължите?',
    rejectInspectionDescription: 'Моля, потвърдете, за да продължите',
    noInspectionForCamera: 'Няма инспекция от камера',
    InspectionImageError_timeout: 'Няма отговор от SmartALot (timeout)',
    InspectionImageError_api: 'Невалиден отговор от SmartALot',
    min3chars: 'Моля, въведете поне 3 знака',
    select_park_area: 'Моля, първо изберете зона за да чертаете.',
    outside_bounds: 'Извън позволени граници!'
  },
  tableRows: {
    minutes: 'минути',
    day: 'ден',
    days: 'дни',
    translated: 'Преведен',
    app_manual: 'Ръчно',
    app_ocr: 'Снимка',
    active: 'Активен',
    inactive: 'Неактивен',
    pending_payment: 'Чака плащане',
    pending_review: 'Чака одобрение',
    declined: 'Отказано',
    expired: 'Изтекло',
    canceled: 'Анулирано',
    electric_car: 'Електрическа кола',
    municipal_car: 'Общинска кола',
    other: 'Друго',
    repatriation: 'Репатриране',
    tire_block: 'Заскобяване',
    administrative: 'Административна глоба',
    not_paid: 'Не е платено',
    paid: 'Платено',
    discount: 'Отстъпка',
    payment: 'Плащане',
    durationHoursAndMinutes: '{0} часа и {1} минути',
    durationHours: '{0} часа',
    durationMinutes: '{0} минути',
    paid_with_cash: 'Платено в брой',
    paid_with_wire: 'Платено с карта',
    cash: 'Платено в брой',
    wire: 'Платено с карта'
  },
  select: {
    active: 'Активно',
    inactive: 'Неактивно',
    pending_review: 'Чака одобрение',
    pending_payment: 'Чака плащане',
    declined: 'Отказано',
    expired: 'Изтекло',
    canceled: 'Анулирано',
    not_paid: 'Не е платено',
    paid: 'Платено',
    electric_car: 'Електрическа кола',
    municipal_car: 'Общинска кола',
    other: 'Друго',
    repatriation: 'Репатриране',
    tire_block: 'Заскобяване',
    administrative: 'Сминистративна глоба'
  },
  notification: {
    500: 'Опа, нещо се объка!',
    success_approve_subscription: 'Успешно одобрен абонамент.',
    success_cancel_subscription: 'Успешно отказване на абонамент',
    success_cancel_fine: 'Успешно отказване на глобата',
    success_cancel_payment: 'Успешно отказване на плащянето',
    success_password_reset_request: 'Успешно изпратен имейл за подновяване на паролата.',
    success: 'Успешно',
    success_export: 'Успешно! Моля проверете си имейл адреса.',
    fail_export: 'Опа, нещо се объка! Експортът не беше усепешен.',
    subscription_payment_invalid_status: 'Не може да бъдат показани плащания за абнамент с такъв статус',
    inspection_confirmed: 'Потвърдена инспекция',
    inspection_rejected: 'Отхвърлена проверка'
  },
  tooltip: {
    delete_duration: 'Изтрий повтаряне',
    AllowProrateDuration: 'Разрешете пропорционална продължителност',
    total_amount: 'Обща сума',
    service: 'Услуга'
  },
  chart: {
    fine: 'Глоби',
    parking: 'Паркиране',
    parking_daily: 'Дневно паркиране',
    parking_hourly: 'Часово паркиране',
    blocked: 'Блокиран',
    in_tolerance: 'В толеранс',
    block_request_created: 'Изтекъл толеранс, чака заскобяване',
    block_request_taken: 'Взета заявка заскобяване',
    expired_tolerance_waiting_for_tyreblock: 'Изтекъл толеранс, изчаква заскобяване',
    request_for_release: 'Създадена заявка за освобождване',
    release_request_created: 'Създадена заявка за освобождване',
    taken_request_for_release: 'Взета заявка за освобождаване',
    release_request_taken: 'Взета заявка за освобождаване',
    block_request_initialized: 'Създадена заявка за заскобяване',
    expired: 'Изтекли',
    unblocked: 'Освободен',
    subscription: 'Абонамент',
    repatriation_request_created: 'Създадена заявка за репатриране',
    repatriation_request_taken: 'Взета заявка репатриране',
    repatriation_on_the_way: 'Репатриран - на път',
    repatriation_at_parking: 'Оставен на паркинг',
    repatriation_released: 'Репатрирането е освободено',
    valid_check_with_payment: 'Валидни проверки с плащане',
    in_tolerance_without_payment: 'В толеранс без плащане',
    pending: 'Изчакващи',
    confirmed: 'Потвърдени',
    auto_confirmed: 'Потвърдени автоматично',
    rejected: 'Отказани',
    auto_rejected: 'Отказани автоматично',
    has_payment: 'Има плащане',
    employees_total: 'Общо',
    employees_inspectors: 'Инспектори',
    employees_tyreblockers: 'Заскобяващи',
    employees_repatriators: 'Репатриращи',
    employees_cameras: 'Камери',
    actions_inspections: 'Проверки',
    actions_inspections_camera: 'Проверки от камери',
    actions_tyre_blocks_requested: 'Заявки за скоби',
    actions_tyre_blocks_executed: 'Поставени скоби',
    actions_tyre_blocks_requested_camera: 'Заявки за скоби(камери)',
    actions_tyre_blocks_executed_camera: 'Поставени скоби(камери)',
    actions_repatriations_requested: 'Заявки за репатриране',
    actions_repatriations_executed: 'Репатрирани',
    actions_repatriations_requested_camera: 'Заявки за репатриране(камери)',
    actions_repatriations_executed_camera: 'Репатрирани(камери)',
    payment_parking: 'Паркиране',
    payment_fines: 'Глоби',
    payment_subscriptions: 'Абонаменти',
    employees_inspectors_total: 'Служители',
    employees_inspectors_inspections: 'Проверки',
    employees_inspectors_requests: 'Заявки',
    employees_inspectors_fines: 'Глоби',
    employees_inspectors_no_actions: 'Без действие',
    employees_tyre_blockers_total: 'Служители',
    employees_tyre_blockers_blocked: 'Блокирани',
    employees_tyre_blockers_canceled: 'Отказани',
    employees_repatriators_total: 'Служители',
    employees_repatriators_blocked: 'Блокирани',
    employees_repatriators_canceled: 'Отказани',
    compare_inspectors_inspections: 'Проверки',
    compare_inspectors_requests: 'Заявки',
    compare_inspectors_fines: 'Глоби',
    compare_inspectors_no_actions: 'Без действие',
    compare_tyre_blockers_blocked: 'Блокирани',
    compare_tyre_blockers_canceled: 'Отказани',
    compare_repatriators_blocked: 'Блокирани',
    compare_repatriators_canceled: 'Отказани',
    active: 'Активен',
    ready: 'Готов',
    canceled: 'Анулиран',
    inactive: 'Неактивен',
    pending_payment: 'Чака Плащане',
    pending_review: 'Чака одобрение',
    no_checks: 'Няма проверени коли',
    PrintImages: 'Снимки от принтирана глоба'
  },
  label: {
    development: 'dev',
    production: 'prod',
    Inspector: 'Служители',
    InspectorNoPrinter: 'Служители - без принтер',
    Stores: 'Магазини',
    confirmPayment: 'Потвърдете получено плащане?',
    welcome: 'Добре дошли в {0}',
    mobzzo_admin_panel: 'Административен панел',
    dashboard_message: 'Най-добрите решения за паркиране за осигуряване на ефективно управление на паркирането.'
  }
}
